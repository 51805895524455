var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: !_vm.beat.published ? _vm.tooltip1 : "",
          expression: " !beat.published ? tooltip1 : '' ",
        },
      ],
      staticClass: "beat-table",
      class: { hasError: !_vm.beat.published },
    },
    [
      _c("td", { staticClass: "beat-table__select" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checkIfSelected,
              expression: "checkIfSelected",
            },
          ],
          staticClass: "formCheckbox grey",
          class: { checked: _vm.checkIfSelected },
          attrs: { type: "checkbox", id: _vm.beat.id },
          domProps: {
            checked: Array.isArray(_vm.checkIfSelected)
              ? _vm._i(_vm.checkIfSelected, null) > -1
              : _vm.checkIfSelected,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.checkIfSelected,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.checkIfSelected = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.checkIfSelected = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.checkIfSelected = $$c
              }
            },
          },
        }),
        _c("label", { staticClass: "grey", attrs: { for: _vm.beat.id } }),
      ]),
      _c("td", { staticClass: "beat-table__image" }, [
        _c("img", {
          staticClass: "beat-table__image",
          attrs: {
            src: _vm.beat.image_url ? _vm.beat.image_url : "./img/picture.svg",
          },
        }),
      ]),
      _c("td", { staticClass: "beat-table__title" }, [
        _c(
          "div",
          { staticClass: "flexbox" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: { name: "BeatEdit", params: { id: Number(_vm.beat.id) } },
                },
              },
              [_vm._v(_vm._s(_vm.beat.name))]
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.beat.published,
                  expression: "!beat.published",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip.right",
                  value: _vm.tooltip2,
                  expression: "tooltip2",
                  modifiers: { right: true },
                },
              ],
              staticClass: "beat-table__pending",
            }),
          ],
          1
        ),
      ]),
      _c("td", [_vm._v(_vm._s(_vm.beat.artist_type.name))]),
      _c(
        "td",
        { staticClass: "beat-table__files" },
        [
          _c("beat-uploaded-files", {
            attrs: { beat: _vm.beat, "border-class": false },
          }),
        ],
        1
      ),
      _c(
        "td",
        { staticClass: "beat-table__toggle" },
        [
          _c("toggle-button", {
            attrs: { color: "#0269FF", labels: true },
            model: {
              value: _vm.demoDownload,
              callback: function ($$v) {
                _vm.demoDownload = $$v
              },
              expression: "demoDownload",
            },
          }),
        ],
        1
      ),
      _c(
        "td",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeModals,
              expression: "closeModals",
            },
          ],
          staticClass: "beat-table__edit",
        },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn--sm btn--black btn--text",
              staticStyle: { padding: "0" },
              attrs: {
                to: { name: "BeatEdit", params: { id: Number(_vm.beat.id) } },
              },
            },
            [_vm._v("EDIT")]
          ),
          _c(
            "a",
            {
              staticClass: "beat-table__iconForDropdown dropdown",
              on: { click: _vm.toggleDlModal },
            },
            [
              _c("icon", {
                staticClass: "icon-share",
                attrs: { name: "download" },
              }),
              _vm.showDlModal
                ? _c(
                    "ul",
                    { staticClass: "dropdown__menu dropdown__menu--right" },
                    [
                      _vm.beat.mp3_file_name
                        ? _c("li", { staticClass: "dropdown__item" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFileType({
                                      beatId: _vm.beat.id,
                                      fileType: _vm.AudioFileType.Mp3,
                                    })
                                  },
                                },
                              },
                              [_vm._v("Download Mp3")]
                            ),
                          ])
                        : _vm._e(),
                      _vm.beat.wav_file_name
                        ? _c("li", { staticClass: "dropdown__item" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFileType({
                                      beatId: _vm.beat.id,
                                      fileType: _vm.AudioFileType.Wav,
                                    })
                                  },
                                },
                              },
                              [_vm._v("Download Wav")]
                            ),
                          ])
                        : _vm._e(),
                      _vm.beat.trackout_file_name ||
                      _vm.beat.trackout_external_url
                        ? _c("li", { staticClass: "dropdown__item" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFileType({
                                      beatId: _vm.beat.id,
                                      fileType: _vm.AudioFileType.Trackout,
                                    })
                                  },
                                },
                              },
                              [_vm._v("Download Trackout")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "beat-table__iconForDropdown",
              on: { click: _vm.toggleShareLinkModal },
            },
            [
              _c("icon", {
                staticClass: "icon-share",
                attrs: { name: "share-filled" },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showShareLinkModal,
                      expression: "showShareLinkModal",
                    },
                  ],
                  staticClass: "beat-table__iconForDropdown-dropdown",
                },
                [
                  _c("p", [_vm._v("Copy link")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shareLinkUrl,
                        expression: "shareLinkUrl",
                      },
                    ],
                    ref: "shareLinkUrl",
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { type: "text" },
                    domProps: { value: _vm.shareLinkUrl },
                    on: {
                      click: _vm.copyShareLink,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.shareLinkUrl = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }