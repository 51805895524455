var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-producer" },
    [
      _c("header", { staticClass: "header" }, [
        _c("div", { staticClass: "container headerContainer" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "headerContainer__search formGroup" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchKeyword,
                    expression: "searchKeyword",
                    modifiers: { trim: true },
                  },
                ],
                staticClass:
                  "formInput formInput--withIcon formInput--fullWidth formInput--rounded",
                attrs: { placeholder: "Search Beat" },
                domProps: { value: _vm.searchKeyword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchKeyword = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("icon", {
                staticClass: "formInput__icon",
                attrs: { name: "search" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "headerContainer__alignRight" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn--sm btn--black btn--text btn--withIconLeft",
                  attrs: { to: { name: "BeatsReorder" } },
                },
                [
                  _c("icon", { attrs: { name: "reorder" } }),
                  _vm._v("REORDER BEATS"),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn--sm btn--black btn--text btn--withIconLeft",
                  attrs: {
                    to: {
                      name: "BeatsUpload",
                      query: { type: _vm.UploadType.Public },
                    },
                  },
                },
                [
                  _c("icon", { attrs: { name: "picture" } }),
                  _vm._v("UPLOAD ARTWORKS"),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("transition", { attrs: { name: "slide-in" } }, [
        _vm.selectedBeats.length > 0
          ? _c(
              "div",
              { staticClass: "container wrapper--filled wrapper--paddingLg" },
              [
                _c(
                  "p",
                  {
                    staticClass: "h-textCenter",
                    staticStyle: { "margin-bottom": "20px" },
                  },
                  [
                    _vm._v(
                      "Here you can delete beats or linked files. This cannot be undone so proceed with caution."
                    ),
                  ]
                ),
                _c("div", { staticClass: "flexbox flexbox--center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--sm btn--danger",
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmActionModal(
                            _vm.UpdateBeatAction.DeleteBeat
                          )
                        },
                      },
                    },
                    [_vm._v("Delete beats")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--sm btn--primary",
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmActionModal(
                            _vm.UpdateBeatAction.DeleteTaggedFile
                          )
                        },
                      },
                    },
                    [_vm._v("Delete tagged Mp3 files")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--sm btn--primary",
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmActionModal(
                            _vm.UpdateBeatAction.DeleteWav
                          )
                        },
                      },
                    },
                    [_vm._v("Delete wav files")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--sm btn--primary",
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmActionModal(
                            _vm.UpdateBeatAction.DeleteTrackout
                          )
                        },
                      },
                    },
                    [_vm._v("Delete trackouts")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--sm btn--primary",
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmActionModal(
                            _vm.UpdateBeatAction.DeleteArtwork
                          )
                        },
                      },
                    },
                    [_vm._v("Delete artowrks")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "section",
        {
          staticClass: "section",
          class: { "h-mt-160 section--flexCenter": _vm.beats.length === 0 },
        },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "container container--fullWidth" },
                [_c("VLoadSpinner")],
                1
              )
            : _vm.beats.length === 0
            ? _c(
                "div",
                { staticClass: "container container--fullWidth" },
                [
                  _c("h2", [_vm._v("You haven't uploaded any beats yet.")]),
                  _c("p", [_vm._v("Get started today!")]),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn--primary",
                      attrs: {
                        to: {
                          name: "BeatsUpload",
                          query: { type: _vm.UploadType.NewBeat },
                        },
                      },
                    },
                    [_vm._v("Upload Beats")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "container container--fullWidth wrapper--filled",
                },
                [
                  _c("table", [
                    _c("thead", [
                      _c("tr", { staticClass: "beat-table" }, [
                        _c(
                          "th",
                          {
                            staticClass: "beat-table__select",
                            attrs: { width: "10" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectedBeats.length > 0,
                                  expression: "selectedBeats.length > 0",
                                },
                              ],
                              staticClass: "formCheckbox grey",
                              attrs: {
                                id: "select-all-beats",
                                type: "checkbox",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.TOGGLE_FULL_SELECT_DESELECT(
                                    _vm.beats
                                  )
                                },
                              },
                            }),
                            _c("label", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectedBeats.length > 0,
                                  expression: "selectedBeats.length > 0",
                                },
                              ],
                              staticClass: "grey",
                              attrs: { for: "select-all-beats" },
                            }),
                          ]
                        ),
                        _c("th", {
                          staticClass: "beat-table__image",
                          attrs: { width: "70" },
                        }),
                        _c("th", [_vm._v("BEAT NAME")]),
                        _c("th", [_vm._v("ARTIST TYPE")]),
                        _c("th", { staticClass: "beat-table__files" }, [
                          _vm._v("FILES UPLOADED"),
                        ]),
                        _c("th", { staticClass: "beat-table__toggle" }, [
                          _vm._v("TAGGED DL"),
                        ]),
                        _c("th", {
                          staticClass: "beat-table__edit",
                          attrs: { width: "120" },
                        }),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.beats, function (beat) {
                        return _c("BeatTableItem", {
                          key: beat.id,
                          attrs: { beat: beat },
                        })
                      }),
                      1
                    ),
                  ]),
                  _c("pagination", {
                    attrs: {
                      "page-index": _vm.pageIndex,
                      numberOfPages: _vm.numberOfPages,
                    },
                    on: {
                      changePage: _vm.fetchNewBeats,
                      fetchAll: function ($event) {
                        return _vm.fetchBeats(null)
                      },
                    },
                  }),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h3", [_vm._v("Beats ")]),
      _c("h1", [_vm._v("Catalog ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }